<template>
  <div :class="$style.TechLayout">
    <main :class="$style.page">
      <slot />
    </main>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" module>
.TechLayout {
  position: relative;
  width: 100%;
  height: 100%;
}

.page {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - #{$footer-h});
  padding-top: 12rem + $header-h;
  padding-bottom: 9rem;

  @include respond-to(sm) {
    padding-top: 110px;
  }
}
</style>
